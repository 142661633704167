<template>
  <div class="about">
    <TopBar title="说明">
      <BackIcon></BackIcon>
    </TopBar>

    <v-container class="px-5">
      <TopInfo :title="title" :img="imgPath" textClass="text-subtitle-1"></TopInfo>
      <div class="text-body-2 grey--text">
        为了能给您提供更准确、更有个性化的服务,「Na+课表」需要使用您的智慧校园密码,「Na+课表」会按照本隐私协议权政策的规定使用和披露您的个人信息，并以高度的勤勉、审慎义务对待这些信息。
      </div>
      <div class="text-body-2 grey--text mt-5">
        当进行学号绑定时，智慧校园密码将会存储到服务器中以便获取课程信息等其他数据。如果你希望能够更方便的进行课程查询等功能，请选择「绑定学号」；如果你担心密码有泄漏的风险，请不要进行「绑定学号」。
      </div>
      <div class="text-body-2 grey--text mt-5">
        「Na+课表」不会向任何第三方提供、出售、出租、分享或交易您的个人信息，使用过程中所产生的任何信息将只保存在你的本机中，当你清除数据后，课程信息等也将会一同删除。
      </div>
    </v-container>
  </div>
</template>

<script>
import { setChildViewMixin } from 'common/mixin'
import privacy from "assets/img/today/privacy.svg"
import privacyDark from "assets/img/today/privacyDark.svg"

export default {
  name: 'Privacy',
  mixins: [setChildViewMixin],
  data() {
    return {
      title: '隐私协议'
    }
  },
  computed: {
    imgPath() {
      return this.isDark ? privacyDark : privacy
    },
  },
}
</script>

<style scoped>

</style>